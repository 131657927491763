import { render, staticRenderFns } from "./MessageWrite.vue?vue&type=template&id=6cf6b254&scoped=true&"
import script from "./MessageWrite.vue?vue&type=script&lang=js&"
export * from "./MessageWrite.vue?vue&type=script&lang=js&"
import style0 from "./MessageWrite.vue?vue&type=style&index=0&id=6cf6b254&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf6b254",
  null
  
)

export default component.exports